import { SubmitHandler, useForm } from "react-hook-form";
import AuthInput from "../components/ui/input/AuthInput";
import AuthLayout from "../components/auth/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../common/firebase";
import {
	GoogleAuthProvider,
	signInWithEmailAndPassword,
	signInWithPopup,
	signInWithRedirect,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { useState } from "react";
import AuthError, { AuthErrorType } from "../components/auth/AuthError";
import { useTranslation } from "react-i18next";
import Button from "../components/ui/button/Button";
import GoogleButton from "../components/ui/button/GoogleButton";

export type Inputs = {
	email: string;
	password: string;
};

function Login() {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>();

	const [error, setError] = useState<AuthErrorType | null>(null);
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		setLoading(true);
		signInWithEmailAndPassword(auth, data.email, data.password)
			.then(() => {
				navigate("/home");
			})
			.catch((error: FirebaseError) => {
				switch (error.code) {
					case "auth/wrong-password":
						setError({
							title: t("auth.errors.wrong-password.title"),
							bodyLines: [t("auth.errors.wrong-password.body")],
						});
						break;
					case "auth/user-not-found":
						setError({
							title: t("auth.errors.email-not-found.title"),
							bodyLines: [t("auth.errors.email-not-found.body")],
						});
						break;
					default:
						setError({
							title: error.name,
							bodyLines: [error.message],
						});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	function onGoogle() {
		const provider = new GoogleAuthProvider();
		signInWithPopup(auth, provider)
			.then(() => {
				navigate("/home");
			})
			.catch((error) => {
				console.log("error", error);
			});
	}

	return (
		<AuthLayout onSubmit={handleSubmit(onSubmit)}>
			<h1 className="text-center text-3xl mb-1">{t("auth.login.welcome")}</h1>
			<p className="text-center mb-6 text-sm">{t("auth.login.title")}</p>
			<GoogleButton onClick={onGoogle} />
			<div className="w-full flex items-center justify-center py-6">
				<div className="h-px bg-grey flex-grow"></div>
				<p className="px-2">{t("auth.login.or")}</p>
				<div className="h-px bg-grey flex-grow"></div>
			</div>
			<AuthInput
				name="email"
				label={t("auth.login.email")}
				type="email"
				error={errors.email}
				autoFocus={true}
				register={register("email", {
					required: t("auth.errors.email-empty"),
				})}
			/>
			<div className="h-1"></div>
			<AuthInput
				name="password"
				label={t("auth.login.password")}
				type="password"
				error={errors.password}
				register={register("password", {
					required: t("auth.errors.password-empty"),
					minLength: {
						value: 6,
						message: t("auth.errors.minumun-passsword-length"),
					},
				})}
			/>
			<Link
				to="/forgot-password"
				className="text-sm font-medium text-blue mb-4 cursor-pointer"
			>
				{t("auth.login.forgot-password")}
			</Link>
			{error && <AuthError title={error.title} bodyLines={error.bodyLines} />}
			<div className="h-4"></div>
			<Button name={t("auth.login.continue")} type="submit" loading={loading} />
			<p className="mt-3">
				{t("auth.login.dont-have-account")}{" "}
				<Link to="/signup" className="text-blue">
					{t("auth.login.sign-up")}
				</Link>
			</p>
		</AuthLayout>
	);
}

export default Login;
