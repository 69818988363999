import { useQuery } from "react-query";
import { get } from "../http";

async function getUrgentRepair() {
	return await get<{ cost: number; available: boolean }>(
		"config/urgent-repair"
	);
}

export default function useUrgentRepair() {
	const { isLoading, error, data, refetch } = useQuery<{
		cost: number;
		available: boolean;
	}>("getUrgentRepair", getUrgentRepair);

	return {
		isLoading,
		error,
		data,
		refetch,
	};
}
