import { useTranslation } from "react-i18next";
import { OrderStatus } from "../types";

function StatusTag({ status }: { status: OrderStatus }) {
	const { t } = useTranslation();

	const colors = {
		ACCEPTED: "bg-yellow",
		ACTIVE: "bg-green",
		REJECTED: "bg-red",
		CANCELED: "bg-red",
		DONE: "bg-blue",
	};

	return (
		<span className={`${colors[status]} text-white px-3 py-1 rounded`}>
			{t("status." + status.toLowerCase())}
		</span>
	);
}

export default StatusTag;
