import { useTranslation } from "react-i18next";
import { Order } from "../../common/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import useBreakpoints from "../../common/useBreakpoints";
import StatusTag from "../../common/components/statusTag";

function TitleBox({ order }: { order: Order }) {
	const { t, i18n } = useTranslation();
	const lang = i18n.language;

	const done = order.orderStatus === "DONE";

	const { isMobile } = useBreakpoints();

	if (isMobile) {
		return (
			<div>
				<h1 className="font-bold text-lg">{order.getName(lang)}</h1>
				<p className="mt-2">{order.repairDesc}</p>
				{done && (
					<p className="text-blue mt-4 border-b border-grey pb-2">
						<span className="mr-2">
							<FontAwesomeIcon icon={faCalendarAlt} />
						</span>
						{t("orderdetails.completed-on") +
							" " +
							formatDate(order.scheduledTime)}
					</p>
				)}
				<p className="text-green mt-2 mb-2">
					<span className="mr-2">
						<FontAwesomeIcon icon={faCheck} />
					</span>
					{t("orderdetails.scheduled-on") + " " + formatDate(order.createdAt)}
				</p>
				<StatusTag status={order.orderStatus} />
			</div>
		);
	}

	return (
		<div>
			<h1 className="font-bold text-3xl text-blue">{order.getName(lang)}</h1>
			<p className="mt-2 max-w-2xl">{order.repairDesc}</p>
			{done && (
				<p className="text-blue mt-4">
					<span className="mr-2">
						<FontAwesomeIcon icon={faCalendarAlt} />
					</span>
					{t("orderdetails.completed-on") +
						" " +
						formatDate(order.scheduledTime)}
				</p>
			)}
			<p className="text-green mt-3 mb-2">
				<span className="mr-2">
					<FontAwesomeIcon icon={faCheck} />
				</span>
				{t("orderdetails.scheduled-on") + " " + formatDate(order.createdAt)}
			</p>
			<StatusTag status={order.orderStatus} />
		</div>
	);
}

function formatDate(date: Date) {
	return (
		date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
	);
}

export default TitleBox;
