import { useQuery } from "react-query";
import { get } from "../http";
import { Config } from "../types";

async function getConfig() {
	return await get<Config>("config");
}

export default function useConfig() {
	const { isLoading, error, data, refetch } = useQuery<Config>(
		"getConfig",
		getConfig
	);

	return {
		isLoading,
		error,
		data,
		refetch,
	};
}
