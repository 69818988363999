// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
	apiKey: "AIzaSyBV7Os4XPo122ZdfzY3g3OXbVUTo7SV1D4",
	authDomain: "auth.kulturnimajstor.rs",
	//authDomain: "majstor-7d045.firebaseapp.com",
	projectId: "majstor-7d045",
	storageBucket: "majstor-7d045.appspot.com",
	messagingSenderId: "833444797449",
	appId: "1:833444797449:web:b1de33601e20dba5ccecd8",
	measurementId: "G-66TE2F87DV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
auth.useDeviceLanguage();

// Initialize Firebase Analytics
getAnalytics(app);

export default app;
